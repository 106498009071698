import React from 'react';

function Home() {
    return (
        <>
            整理一些非官方的實用小元件，提供同仁使用 <br /><br />
            <a href="https://github.com/goodchuck2274/mui-component" target='_blank'>Github 專案</a>
        </>
    );
}

export default Home;