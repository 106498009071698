import React, { useState } from 'react';

import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';

import WheelComponent from './components/WheelComponent'

export default function Main() {

    const colorArr = [
        '#435a9e',
        '#e2ba60',
        '#be5252',
        '#74a35e',
        '#B35C37',
        '#66327C',
        '#268785'
    ];
    const initSegments = [
        '選項 1',
        '選項 2',
        '選項 3',
        '選項 4'
    ]
    const initSegColors = [
        '#435a9e',
        '#74a35e',
        '#e2ba60',
        '#be5252'
    ]

    const [listContent, setListContent] = useState('');
    const [segments, setSegments] = useState(initSegments);
    const [segColors, setSegColors] = useState(initSegColors);
    const [winner, setWinner] = useState('')
    const [err, setErr] = useState(false)
    const [errTxt, setErrTxt] = useState('')

    function genSegments(c) {
        let arr = c.split(/\n/);
        if (arr.length > 50) {
            setErr(true);
            setErrTxt('不能超過30個選項');
            return
        }

        setListContent(c);
        setErr(false);
        setErrTxt('');
        let j = 0;
        let ia = [];
        let ca = [];
        for (let i = 0; i < arr.length; i++) {
            if (arr[i] && arr[i].trim() !== '') {
                ia.push(arr[j]);
                let cl = colorArr.length;
                if (i === (arr.length - 1) && (j % cl) === 0 && j !== 0) {
                    ca.push('#e2ba60');
                } else {
                    ca.push(colorArr[j % cl]);
                }
                j++;
            }
        }
        if (j > 0) {
            setSegments(ia);
            setSegColors(ca);
        }
    }

    const onFinished = (winner) => {
        setWinner(winner);
    }

    const clear = () => {
        setListContent('');
        setSegColors(initSegColors);
        setSegments(initSegments);
        setWinner('');
    }

    let winnerDiv = [];
    if (winner) {
        winnerDiv = (
            <>
                中籤者: <span style={{ fontSize: '1.7rem', color: 'tomato', paddingLeft: 20 }}>{winner}</span>
            </>
        )
    } else {
        winnerDiv = (
            <>
                請點選轉盤進行抽籤<span style={{ fontSize: '1.7rem' }}>&nbsp;</span>
            </>
        )
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={12} style={{ paddingTop: 30 }}>
                <Card style={{ padding: 15 }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={4}>
                            <h2>轉盤</h2>
                            請輸入抽籤選項，一行代表一個選項
                            <TextField
                                id="listContent"
                                placeholder='選項 1
選項 2
選項 3
選項 4'
                                value={listContent}
                                multiline
                                rows={10}
                                fullWidth
                                onChange={(event) => {
                                    genSegments(event.target.value);
                                }}
                                error={err}
                                helperText={errTxt}
                            />
                            <Button
                                style={{ marginTop: 10 }}
                                onClick={clear}>
                                清除
                            </Button>
                        </Grid>
                        <Grid item xs={12} md={8} style={{ textAlign: 'center' }}>
                            <Card style={{ padding: 10, marginBottom: 10, textAlign: 'left', backgroundColor: '#f3e3bf' }}>
                                {winnerDiv}
                            </Card>
                            <WheelComponent
                                segments={segments}
                                segColors={segColors}
                                onFinished={(winner) => onFinished(winner)}
                                primaryColor="#59908b"
                                primaryColoraround="#ffffffb4"
                                contrastColor="white"
                                buttonText={'Go'}
                                isOnlyOnce={false}
                                size={200}
                                upDuration={50}
                                downDuration={500}
                            />
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
        </Grid>
    );
}
